import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './Home';
import BookingDetails from './BookingDetails';
import BookingDetails_fe from './BookingDetailsFullEdit';
import BookingDetails_c from './BookingDetailsClient';
import Editor from './Editor';
import Editorfrazier from './Editorfrazier';
import Approval from './Approval';
import Today from './Today';
import SpecialOrder from './SpecialBooking';
import OrlandoBooking from './Orlando';

function GoogleAnalyticsTracker() {
  const location = useLocation();

  useEffect(() => {
    if (window.gtag) {
      window.gtag('config', 'G-VCYNLC0P4N', {
        page_path: location.pathname,
      });
    }
  }, [location]);

  return null; // This component does not render anything
}

function App() {
  useEffect(() => {
    // Check if HEROKU environment variable is set to "1"
    if (process.env.REACT_APP_HEROKU === '1') {
      // Redirect to HTTPS if the current protocol is HTTP
      if (window.location.protocol !== 'https:') {
        window.location.href = 'https:' + window.location.href.substring(window.location.protocol.length);
      }
    }
  }, []);

  return (
    <Router>
      <GoogleAnalyticsTracker /> {/* Google Analytics Tracker */}
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/bookingdetails/:id" element={<BookingDetails_c />} />
        <Route path="/bookingownerfulledit/:id" element={<BookingDetails_fe />} />
        <Route path="/editor" element={<Editor />} />
        <Route path="/fYCgvFyRJzLtqUoVahwQoryMosFmCjTdaTnoPXrTPBeP" element={<Editor />} />
        <Route path="/editorfrazier" element={<Editorfrazier />} />
        <Route path="/approval" element={<Approval />} />
        <Route path="/today" element={<Today />} />
        <Route path="/special" element={<SpecialOrder />} />
        <Route path="/orlando" element={<OrlandoBooking />} />
      </Routes>
    </Router>
  );
}

export default App;
