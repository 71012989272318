import React, { useState, useEffect } from 'react';
import styles from './SpecialBooking.module.css';

function SpecialOrder() {
    const [specialBooking, setSpecialBooking] = useState({
        email: '',
        hours: 1,
        pricePerHour: 124,
        estimatedPrice: 124,
        price: '',
        shortCode: '',
        url: ''
    });

    useEffect(() => {
        setSpecialBooking(prevState => ({
            ...prevState,
            estimatedPrice: prevState.hours * prevState.pricePerHour
        }));
    }, [specialBooking.hours, specialBooking.pricePerHour]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSpecialBooking({
            ...specialBooking,
            [name]: value
        });
    };

    const copyEstimatedToPrice = () => {
        setSpecialBooking(prevState => ({
            ...prevState,
            price: prevState.estimatedPrice
        }));
    };

    const generateShortCode = () => {
        return Math.random().toString(36).substring(2, 6).toUpperCase();
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const shortCode = generateShortCode();
        setSpecialBooking({ ...specialBooking, shortCode });

        fetch('/special', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...specialBooking, shortCode }),
        })
        .then(response => response.json())
        .then(data => {
            setSpecialBooking({ ...specialBooking, url: data.url });
        })
        .catch(error => console.error('Error:', error));
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(specialBooking.url);
    };

    return (
        <div className={styles.container}>
            <h2 style={{ textAlign: 'center' }}>Special Booking</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label className={styles.label}>
                        Email:&nbsp;
                        <input type="email" name="email" value={specialBooking.email} onChange={handleInputChange} className={styles.input} />
                    </label>
                </div>
                <div>
                    <label className={styles.label}>
                        Hours:&nbsp;
                        <select name="hours" value={specialBooking.hours} onChange={handleInputChange} className={styles.select}>
                            {[...Array(8).keys()].map(i => <option key={i + 1} value={i + 1}>{i + 1}</option>)}
                        </select>
                    </label>
                </div>
                <div>
                    <label className={styles.label}>
                        Price Per Hour:&nbsp;
                        <input type="number" name="pricePerHour" value={specialBooking.pricePerHour} onChange={handleInputChange} className={styles.input} />
                    </label>
                </div>
                <div>
                    <label className={styles.label}>
                        Estimated Price:&nbsp;
                        <span>{specialBooking.estimatedPrice}</span>
                        <button type="button" onClick={copyEstimatedToPrice} className={styles.button}>Copy to Price</button>
                    </label>
                </div>
                <div>
                    <label className={styles.label}>
                        Price:&nbsp;
                        <input type="number" name="price" value={specialBooking.price} onChange={handleInputChange} className={styles.input} />
                    </label>
                </div>
                <button type="submit" className={styles.button}>Submit</button>
                {specialBooking.url && (
                    <div>
                        <input type="text" value={specialBooking.url} readOnly className={styles.input} />
                        <button type="button" onClick={copyToClipboard} className={styles.button}>Copy</button>
                    </div>
                )}
            </form>
        </div>
    );
}

export default SpecialOrder;

