// src/BookingDetails.js
import './BookingDetailsFullEdit.css';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

function BookingDetails_fe() {
  const { id } = useParams();
  const [booking, setBooking] = useState({
    name: '',
    reschedule_link: '',
    cancel_link: '',
    type_name: '',
    email: '',
    address: '',
    phone: '',
    start_date: '',
    end_date: '',
    appt_duration: '',
    access_info: '',
    property_status: '',
    no_show: '',
    notes: '',
    short_code: '',
    edited: 0,
    invoiced: 0,
    shoot_completed: 0,
    gdrive_deliver_link: '',
    gdrive_edit_link: '',
    text_alerts: 0,
    invoice_link: '',
    invoice_type: '',
  });

  useEffect(() => {
    fetch(`/booking/${id}`)
      .then(res => res.json())
      .then(data => {
        setBooking(data);
      })
      .catch(err => console.error('Error fetching data:', err));
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setBooking({
      ...booking,
      [name]: type === 'checkbox' ? (checked ? 1 : 0) : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch(`/booking/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(booking),
    })
      .then(response => response.json())
      .then(data => console.log('Booking updated:', data))
      .catch(error => console.error('Error updating booking:', error));
  };

  if (!booking) return <div>Loading...</div>;

  return (
    <div>
      <h2>Booking Details</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>
            Name:
            <input type="text" name="name" value={booking.name || ''} onChange={handleInputChange} />
          </label>
        </div>
        <div>
          <label>
            Reschedule Link:
            <input type="text" name="reschedule_link" value={booking.reschedule_link || ''} onChange={handleInputChange} />
          </label>
        </div>
        <div>
          <label>
            Cancel Link:
            <input type="text" name="cancel_link" value={booking.cancel_link || ''} onChange={handleInputChange} />
          </label>
        </div>
        <div>
          <label>
            Type Name:
            <input type="text" name="type_name" value={booking.type_name || ''} onChange={handleInputChange} />
          </label>
        </div>
        <div>
          <label>
            Email:
            <input type="email" name="email" value={booking.email || ''} onChange={handleInputChange} />
          </label>
        </div>
        <div>
          <label>
            Address:
            <input type="text" name="address" value={booking.address || ''} onChange={handleInputChange} />
          </label>
        </div>
        <div>
          <label>
            Phone:
            <input type="tel" name="phone" value={booking.phone || ''} onChange={handleInputChange} />
          </label>
        </div>
        <div>
          <label>
            Start Date:
            <input type="text" name="start_date" value={booking.start_date || ''} onChange={handleInputChange} />
          </label>
        </div>
        <div>
          <label>
            End Date:
            <input type="text" name="end_date" value={booking.end_date || ''} onChange={handleInputChange} />
          </label>
        </div>
        <div>
          <label>
            Appointment Duration:
            <input type="text" name="appt_duration" value={booking.appt_duration || ''} onChange={handleInputChange} />
          </label>
        </div>
        <div>
          <label>
            Access Info:
            <input type="text" name="access_info" value={booking.access_info || ''} onChange={handleInputChange} />
          </label>
        </div>
        <div>
          <label>
            Property Status:
            <input type="text" name="property_status" value={booking.property_status || ''} onChange={handleInputChange} />
          </label>
        </div>
        <div>
          <label>
            No Show:
            <textarea name="no_show" value={booking.no_show || ''} onChange={handleInputChange} />
          </label>
        </div>
        <div>
          <label>
            Notes:
            <textarea name="notes" value={booking.notes || ''} onChange={handleInputChange} />
          </label>
        </div>
        <div>
          <label>
            Short Code:
            <input type="text" name="short_code" value={booking.short_code || ''} onChange={handleInputChange} />
          </label>
        </div>
        <div>
          <label>
            Edited:
            <input type="checkbox" name="edited" checked={booking.edited === 1} onChange={handleInputChange} />
          </label>
        </div>
        <div>
          <label>
            Invoiced:
            <input type="checkbox" name="invoiced" checked={booking.invoiced === 1} onChange={handleInputChange} />
          </label>
        </div>
        <div>
          <label>
            Shoot Completed:
            <input type="checkbox" name="shoot_completed" checked={booking.shoot_completed === 1} onChange={handleInputChange} />
          </label>
        </div>
        <div>
          <label>
            Google Drive Delivery Link:
            <input type="text" name="gdrive_deliver_link" value={booking.gdrive_deliver_link || ''} onChange={handleInputChange} />
          </label>
        </div>
        <div>
          <label>
            Google Drive Edit Link:
            <input type="text" name="gdrive_edit_link" value={booking.gdrive_edit_link || ''} onChange={handleInputChange} />
          </label>
        </div>
        <div>
          <label>
            Text Alerts:
            <input type="checkbox" name="text_alerts" checked={booking.text_alerts === 1} onChange={handleInputChange} />
          </label>
        </div>
        <div>
          <label>
            Invoice Link:
            <input type="text" name="invoice_link" value={booking.invoice_link || ''} onChange={handleInputChange} />
          </label>
        </div>
        <div>
          <label>
            Invoice Type:
            <input type="text" name="invoice_type" value={booking.invoice_type || ''} onChange={handleInputChange} />
          </label>
        </div>
        <button type="submit">Save</button>
      </form>
    </div>
  );
}

export default BookingDetails_fe;
