import React, { useState, useEffect } from 'react';
import styles from './Today.module.css';
import { DateTime, Duration } from 'luxon';


function Today() {
  const [bookings, setBookings] = useState([]);
  const [showAllBookings, setShowAllBookings] = useState(false);
  const [showFutureBookings, setShowFutureBookings] = useState(false);
  const [showTomorrowBookings, setShowTomorrowBookings] = useState(false);
  const [showNextThreeDaysBookings, setShowNextThreeDaysBookings] = useState(false);

  useEffect(() => {
    fetch('/bookings')
      .then(res => res.json())
      .then(data => {
        setBookings(data);
      })
      .catch(err => console.error('Error fetching data:', err));
  }, []);


  const timeUntil = (dateString) => {
    if (!dateString) return 'No Date Provided';
    const now = DateTime.now().setZone('utc').setZone('America/New_York', { keepLocalTime: true });
    const eventDate = DateTime.fromISO(dateString, { zone: 'utc' }).setZone('America/New_York', { keepLocalTime: true });
    const diff = eventDate - now;
    if (diff < 0) return 'Event Passed';
    
    // Use Duration.fromMillis to create a Duration object from the difference in milliseconds
    const duration = Duration.fromMillis(diff).shiftTo('months', 'days', 'hours', 'minutes');
    
    if (duration.months > 0) return `${duration.months} mo ${duration.days} d`;
    if (duration.days > 0) return `${duration.days} d`;
    if (duration.hours > 0) return `${duration.hours} hrs`;
    return `${duration.minutes} m`;
};
// No change needed, but make sure your current logic is:
const isFuture = (dateString) => {
  const now = DateTime.now().startOf('day');
  const date = DateTime.fromISO(dateString).startOf('day');
  return date >= now;
};



  const getBookingStatus = (booking) => {
    if (booking.canceled === 1) {
      return "Photoshoot Canceled";
    } else if (booking.shoot_completed !== 1) {
      return "Booking Created :)";
    } else if (booking.shoot_completed === 1 && !booking.edited) {
      return "Pending Editing";
    } else if (booking.edited && (!booking.invoice_link || booking.invoice_link.length <= 2)) {
      return "Pending Final Approval";
    } else if (booking.invoice_link && booking.invoice_link.length > 2 && booking.invoiced !== "1") {
      return "Please Pay Invoice for Instant Photo/Video Delivery";
    } else if (booking.invoiced === "1") {
      return "Booking Completed :)";
    }
    return "Status Unknown";
  };

  function convertDate(dateString) {
    if (!dateString) return '';
  
    // Interpret the date string as UTC
    const dateAsUTC = DateTime.fromISO(dateString, { zone: 'utc' });
  
    // Since the time is actually in EST, not UTC, we set the zone to 'America/New_York' without converting the time
    const dateInEST = dateAsUTC.setZone('America/New_York', { keepLocalTime: true });
  
    // Format the date as EST in "month/day/year [@] hour:minute AM/PM" format
    return dateInEST.toFormat('M/d @ h:mm a');
  };
  
  const isToday = (dateString) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const d = new Date(dateString);
    d.setHours(0, 0, 0, 0);
    return d.getTime() === today.getTime();
  };

  const filteredBookings = () => {
    let result = bookings.filter(booking => booking.start_date);
    result.sort((a, b) => new Date(a.start_date) - new Date(b.start_date));
    
    if (showTomorrowBookings) {
      result = result.filter(booking => isTomorrow(booking.start_date));
    } else if (showNextThreeDaysBookings) {
      result = result.filter(booking => isNextThreeDays(booking.start_date));
    } else if (showFutureBookings) {
      result = result.filter(booking => isFuture(booking.start_date));
    } else if (showAllBookings) {
      // Split into past and future
      const futureBookings = result.filter(booking => isFuture(booking.start_date));
      const pastBookings = result.filter(booking => !isFuture(booking.start_date));
      // Future bookings first, then past bookings
      result = [...futureBookings, ...pastBookings];
    } else {
      // If none of the above flags are true, show only today's bookings
      result = result.filter(booking => isToday(booking.start_date));
    }
    return result;
  };
  
  

  const isNextThreeDays = (dateString) => {
    const now = DateTime.now().startOf('day');
    const inThreeDays = DateTime.now().plus({ days: 3 }).endOf('day');
    const date = DateTime.fromISO(dateString).startOf('day');
    return date >= now && date <= inThreeDays;
  };
  

  function convertDate(dateString) {
    if (!dateString) return '';
  
    const dateAsUTC = DateTime.fromISO(dateString, { zone: 'utc' });
    const dateInEST = dateAsUTC.setZone('America/New_York', { keepLocalTime: true });
    return dateInEST.toFormat('cccc, LLL dd @ h:mm a');
  };

  const isTomorrow = (dateString) => {
    const tomorrow = DateTime.now().plus({ days: 1 }).startOf('day');
    const date = DateTime.fromISO(dateString);
    return date.startOf('day').equals(tomorrow);
  };

  const handleButtonClick = (event, url) => {
    event.stopPropagation();
    window.open(url, '_blank');
  };
  
  const handleBookingClick = (booking, event) => {
    if (window.getSelection().toString()) {
      return;
    }
    window.open(`https://www.xvt.me/x/${booking.short_code}`, '_blank');
  };

  const renderBookingButtons = (booking) => (
    <div className={styles.buttonGroup}>
      <button onClick={(e) => handleButtonClick(e, `/bookingdetails/${booking.booking_id}`)} className={`${styles.smallButton} ${styles.userButton}`}>User</button>
      <button onClick={(e) => handleButtonClick(e, `/bookingownerfulledit/${booking.booking_id}`)} className={`${styles.smallButton} ${styles.fullEditButton} ${styles.fullEditButtonSpecific}`}>Full Edit</button>
    </div>
  );
  

  return (
    <div className={styles.container}>
      <div className={styles.sidebar}>
        <h2>Booking Manager</h2>
          <div className={styles.buttonContainer}>
          <button onClick={() => { setShowAllBookings(false); setShowFutureBookings(false); setShowTomorrowBookings(false); setShowNextThreeDaysBookings(true); }} className={styles.inlineButton}>Next 3 Days</button>
          <button onClick={() => { setShowAllBookings(false); setShowFutureBookings(false); setShowTomorrowBookings(true); setShowNextThreeDaysBookings(false); }} className={styles.inlineButton}>Tomorrow</button>
          <button onClick={() => { setShowAllBookings(false); setShowFutureBookings(false); setShowTomorrowBookings(false); setShowNextThreeDaysBookings(false); }} className={styles.inlineButton}>Today</button>
          <button onClick={() => { setShowAllBookings(true); setShowFutureBookings(false); setShowTomorrowBookings(false); setShowNextThreeDaysBookings(false); }} className={styles.inlineButton}>All Upcoming</button>
          <button onClick={() => { setShowAllBookings(false); setShowFutureBookings(true); setShowTomorrowBookings(false); setShowNextThreeDaysBookings(false); }} className={styles.inlineButton}>All & Historical</button>
          </div>
        <ul className={styles.list}>
          {filteredBookings().map(booking => (
            <li key={booking.booking_id} className={styles.listItem} onClick={(e) => handleBookingClick(booking, e)}>
              <div className={styles.listItemContent}>
                <span className={styles.bookingId}>{booking.booking_id}</span>
                <span className={styles.listItemText}>{booking.name}</span>
                <span className={styles.listItemText}>{`${booking.short_code} | ${convertDate(booking.start_date)}`}</span>
                <span className={`${styles.listItemText} ${styles.timeUntil}`}>
                  {timeUntil(booking.start_date)}
                </span>
                <span className={styles.listItemText}>Status: {getBookingStatus(booking)}</span>
                {renderBookingButtons(booking)}
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.mainContent}>
        {/* Content here */}
      </div>
    </div>
  );
}

export default Today;
