import './BookingDetailsClient.css';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { DateTime } from 'luxon';

function BookingDetails_c() {
  const { id } = useParams();
  const [booking, setBooking] = useState({
    name: '',
    reschedule_link: '',
    cancel_link: '',
    type_name: '',
    email: '',
    address: '',
    phone: '',
    start_date: '',
    end_date: '',
    appt_duration: '',
    access_info: '',
    property_status: '',
    no_show: '',
    notes: '',
    short_code: '',
    edited: 0,
    invoiced: '',
    shoot_completed: 0,
    gdrive_deliver_link: '',
    gdrive_edit_link: '',
    text_alerts: 0,
    invoice_link: '',
    invoice_type: '',
    canceled: 0
  });
  const [notesEdited, setNotesEdited] = useState(false);
  const [showPlaceholder, setShowPlaceholder] = useState(true);

  useEffect(() => {
    fetch(`/booking/${id}`)
      .then(res => res.json())
      .then(data => {
        setBooking(data);
        if (data.notes) {
          setShowPlaceholder(false);
        }
      })
      .catch(err => console.error('Error fetching data:', err));
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBooking({
      ...booking,
      [name]: value,
    });

    if (name === 'notes') {
      setNotesEdited(value !== booking.notes);
      setShowPlaceholder(false);
    }
  };

  const handleFocus = (e) => {
    if (e.target.name === 'notes' && !booking.notes) {
      setShowPlaceholder(false);
    }
  };

  const handleSaveNotes = (e) => {
    e.preventDefault();

    const updatedBookingData = {
      ...booking,
      notes: booking.notes
    };

    fetch(`/booking/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedBookingData),
    })
    .then(response => response.json())
    .then(data => {
      console.log('Notes updated:', data);
      setNotesEdited(false);
      setBooking(data);
      window.location.reload();
    })
    .catch(error => {
      console.error('Error updating notes:', error);
    });
  };

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    if (cleaned.length === 11 && cleaned.startsWith('1')) {
      var match = cleaned.match(/^1(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
      }
    }
    return phoneNumberString;
  }
  function convertDate(dateString) {
    if (!dateString) return '';
  
    // Interpret the date string as UTC
    const dateAsUTC = DateTime.fromISO(dateString, { zone: 'utc' });
  
    // Since the time is actually in EST, not UTC, we set the zone to 'America/New_York' without converting the time
    const dateInEST = dateAsUTC.setZone('America/New_York', { keepLocalTime: true });
  
    // Format the date as EST in "month/day/year [@] hour:minute AM/PM" format
    return dateInEST.toFormat('M/d @ h:mm a');
  };

  if (!booking) return <div>Loading...</div>;

  let showReschedule, showCancel, showPayInvoice, showDownloadPhotos, showAnonymousFeedback, status;

  if (booking.canceled === 1) {
    showReschedule = false;
    showCancel = false;
    showPayInvoice = false;
    showDownloadPhotos = false;
    status = "Photoshoot Canceled";
  } else if (booking.shoot_completed !== 1) {
    showReschedule = true;
    showCancel = true;
    showPayInvoice = false;
    showDownloadPhotos = false;
    status = "Booking Created :)";
  } else if (booking.shoot_completed === 1 && !booking.edited) {
    showReschedule = false;
    showCancel = false;
    showPayInvoice = false;
    showDownloadPhotos = false;
    status = "Pending Editing";
  } else if (booking.edited && (!booking.invoice_link || booking.invoice_link.length <= 2)) {
    showReschedule = false;
    showCancel = false;
    showPayInvoice = false;
    showDownloadPhotos = false;
    status = "Pending Final Approval";
  } else if (booking.invoice_link && booking.invoice_link.length > 2 && booking.invoiced !== 1) {
    showReschedule = false;
    showCancel = false;
    showPayInvoice = true;
    showDownloadPhotos = false;
    status = "Please Pay Invoice for Instant Photo/Video Delivery";
  } else if (booking.invoiced === 1) {
    showReschedule = false;
    showCancel = false;
    showPayInvoice = false;
    showDownloadPhotos = true;
    showAnonymousFeedback = true;
    status = "Booking Completed :)";
  }
  else
{
  showAnonymousFeedback = false;
}

  return (
    <div className="container">
      <h2>XovateStudio 📸</h2>
      <h3>{booking.booking_id}</h3>
      <div className="info-box">
        <div className="content-line">{booking.name || ''}</div>
        <div className="content-line">{booking.phone ? formatPhoneNumber(booking.phone) : ''}</div>
        <div className="content-line">{booking.email || ''}</div>
      </div>
      <div className="info-box property-info">
        <div className="content-line">{booking.address || ''}</div>
        <div className="content-line">
        {convertDate(booking.start_date)}
      {/*  {booking.start_date ? formatEasternTime(booking.start_date) : ''} */} 

        </div>
        <div className="content-line">{booking.property_status || ''}</div>
        <div className="content-line">{booking.access_info || 'No Access Information Stored'}</div>
      </div>
      <div className="status-box">
        <div className="content-line"><strong>Status:</strong> {status}</div>
      </div>
      <form onSubmit={handleSaveNotes} className="form">
        <div className="info-box">
          <label>Notes: (Click to modify)</label>
          <textarea
            className="notes-textarea"
            name="notes"
            value={booking.notes || ''}
            onChange={handleInputChange}
            onFocus={handleFocus}
            placeholder={showPlaceholder ? 'Click here to add notes...' : ''}
          />
          {notesEdited && (
            <button type="submit" className="action-button-notes">Save Changes to Notes</button>
          )}
        </div>
      </form>
      {showReschedule && (
        <button onClick={() => window.location.href = booking.reschedule_link} disabled={!booking.reschedule_link}>Reschedule</button>
      )}
      {showCancel && (
        <button onClick={() => window.location.href = booking.cancel_link} disabled={!booking.cancel_link}>Cancel</button>
      )}
      {showPayInvoice && (
        <button onClick={() => window.location.href = `https://www.xvt.me/api/pay_invoice/${booking.booking_id}`}>Pay Invoice</button>
      )}
      {showDownloadPhotos && (
        <button onClick={() => window.location.href = booking.gdrive_deliver_link}>Download Photos</button>
      )}
      {showAnonymousFeedback && (
        <button className="anonymous-feedback-button" onClick={() => window.open('https://forms.gle/ipFGuNCiT2Xuby6aA', '_blank')}>
       Give Feedback
      </button>
      )}

    </div>
  );
}

export default BookingDetails_c;
