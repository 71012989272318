import React, { useState, useEffect } from 'react';
import styles from './Approval.module.css'; // Import CSS module

function Approval() {
  const [bookings, setBookings] = useState([]);
  const [selectedBookingId, setSelectedBookingId] = useState(null);
  const [selectedBooking, setSelectedBooking] = useState(null);

  useEffect(() => {
    fetch('/bookings')
      .then(res => res.json())
      .then(data => {
        const filteredBookings = data.filter(booking => 
          booking.edited === 1
        );
        setBookings(filteredBookings);
      })
      .catch(err => console.error('Error fetching data:', err));
  }, []);

  const handleBookingSelect = (id) => {
    fetch(`/booking/${id}`)
      .then(res => res.json())
      .then(data => {
        setSelectedBookingId(id);
        setSelectedBooking(data);
      })
      .catch(err => console.error('Error fetching booking details:', err));
  };

  const handleEditingComplete = () => {
    const updatedBooking = { ...selectedBooking, edited: 1 };

    fetch(`/booking/${selectedBookingId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedBooking),
    })
    .then(response => response.json())
    .then(() => {
      alert('Editing marked as complete.');
      setSelectedBooking(updatedBooking);
      setBookings(bookings.map(b => b.booking_id === selectedBookingId ? updatedBooking : b));
    })
    .catch(err => {
      console.error('Error updating booking:', err);
      alert('Failed to mark editing as complete. Please try again.');
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.sidebar}>
        <h2>Bookings to edit</h2>
        <ul className={styles.list}>
          {bookings.map(booking => (
            <li
              key={booking.booking_id}
              className={`${styles.listItem} ${selectedBookingId === booking.booking_id ? styles.listItemActive : ''}`}
              onClick={() => handleBookingSelect(booking.booking_id)}
            >
              <div className={styles.listItemContent}>
                <span>{booking.booking_id}</span>
                <span className={styles.listItemText}>{booking.name}</span>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.mainContent}>
        {selectedBooking ? (
          <div>
            <div className={styles.bookingDetails}>
              <h3>Booking ID: {selectedBooking.booking_id}</h3>
              <div className={styles.buttonGroup}>
                <button className={styles.smallButton} onClick={() => window.open(selectedBooking.gdrive_edit_link, '_blank')}>
                  ⬆ Upload Files Here ⬆
                </button>
                <button className={styles.smallButton} onClick={() => window.open(selectedBooking.gdrive_deliver_link, '_blank')}>
                  ⬇ Download Files Here ⬇
                </button>
                {selectedBooking.edited !== 1 && (
                  <button className={styles.smallButton} onClick={handleEditingComplete}>
                    Click This Once Editing Complete
                  </button>
                )}
                {selectedBooking.edited === 1 && <p>Editing Complete ✓</p>}
              </div>
            </div>
            <iframe 
              src={`https://www.xvt.me/x/${selectedBooking.short_code}`} 
              style={{ width: '100%', height: '800px', border: 'none' }}
              title="Booking Details"
            ></iframe>
          </div>
        ) : (
          <p>Select a booking to view details.</p>
        )}
      </div>
    </div>
  );
}

export default Approval;
