import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Home.css'; // Make sure this points to the correct path of your CSS file
import small from './image/small_house.png';
import medium from './image/medium_house.png';
import large from './image/large_house.png';
import land from './image/land.png';
import gnv from './image/gnv.png';
import orl from './image/orl.png';

function ContactModal({ onClose, isVisible }) {
  if (!isVisible) return null;

  return (
    <div className="modal-backdrop">
      <div className="modal">
        <button className="close-button" onClick={onClose}>X</button>
        <p>Get in touch with us!</p>
        <a href="mailto:nelson@xovate.studio" className="email-link">Email Us</a>
      </div>
    </div>
  );
}

function Home() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [locationSelected, setLocationSelected] = useState('');
  const [currentBackgroundIndex, setCurrentBackgroundIndex] = useState(0);
  const backgrounds = [
    './image/background/background5.jpeg',
    './image/background/background2.jpeg',
    './image/background/background3.jpeg',
    // Add more image paths as needed
  ];
  const navigate = useNavigate();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentBackgroundIndex((currentBackgroundIndex + 1) % backgrounds.length);
    }, 7000); // Change image every 7 seconds to account for the 2-second fade effect

    return () => clearInterval(intervalId);
  }, [currentBackgroundIndex, backgrounds.length]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleLocationSelect = (location) => {
    if (location === 'orlando') {
      navigate('/orlando');
    } else {
      setLocationSelected(location);
    }
  };

  const handleBackButtonClick = () => {
    setLocationSelected('');
  };

  return (
    <div className="container" style={{ backgroundImage: `url(${backgrounds[currentBackgroundIndex]})` }}>
      <div className="background-fade" style={{ opacity: 1 }}></div>
      {/* Always visible header content */}
      <h1>XovateStudio 📸</h1>
      <div className="banner-message">
        <b>ATTENTION GAINESVILLE: </b>Gainesville Service will end on Jun 1st, It has been a pleasure serving the Gainesville community!
      </div>
      <h3>Why hire us? <a className="portlink" href="https://drive.google.com/drive/folders/1XqUwBgj9bObhwHsWr6tiGKJxt7gtn-dz" target="_blank" rel="noopener noreferrer">Portfolio</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a className="portlinkemail" href="mailto:nelson@xovate.studio" target="_blank" rel="noopener noreferrer">Email Us</a> </h3>
      <h3>Where is the appointment? 👀</h3>

      {locationSelected === '' && (
        <div className="location-selection-container">
          <div className="tile location-tile" onClick={() => handleLocationSelect('gainesville')}>
            <img src={gnv} alt="Gainesville" />
            Gainesville
          </div>
          <div className="tile location-tile" onClick={() => handleLocationSelect('orlando')}>
            <img src={orl} alt="Orlando" />
            Orlando
          </div>
        </div>
      )}

      {locationSelected === 'gainesville' && (
        <>
          <div className="back-button-container">
            <button className="back-button" onClick={handleBackButtonClick}>&lt;- Back</button>
          </div>
          <div className="tile-container">
            <a href="https://xovate-2500.youcanbook.me/?TXTNOTIFY=yes" className="tile">
              <img src={small} alt="Up to 2,500 Sqft" />
              Up to 2,500 Sqft
            </a>
            <a href="https://xovate-4000.youcanbook.me/?TXTNOTIFY=yes" className="tile">
              <img src={medium} alt="2,500 Sqft - 4,000 Sqft" />
              2,500 Sqft - 4,000 Sqft
            </a>
            <a href="https://xovate-6000.youcanbook.me/?TXTNOTIFY=yes" className="tile">
              <img src={large} alt="4,000 Sqft - 6,000 Sqft" />
              4,000 Sqft - 6,000 Sqft
            </a>
            <a href="https://xovate-land.youcanbook.me/?TXTNOTIFY=yes" className="tile">
              <img src={land} alt="Land / Lots" />
              Land / Lots
            </a>
          </div>
        </>
      )}

      <div className="footer-links">
        <Link to="/terms.html" target="_blank" rel="noopener noreferrer">Terms & </Link>
        <Link to="/privacy.html" target="_blank" rel="noopener noreferrer">Privacy Policy</Link>
      </div>

      <ContactModal onClose={closeModal} isVisible={isModalOpen} />
    </div>
  );
}

export default Home;
