import React, { useState, useEffect } from 'react';
import styles from './Orlando.module.css';

const pricingTiers = [
  { range: "Up to 1,500 sqft", prices: { "Interior & Exterior Photos": 145, "Cinematic Video": 100, "3D Matterport Virtual Tour with Floor Plan & Dimensions": 100, "Aerial Photography": 50, "Neighborhood Amenities": 45, "Twilight Exteriors": 100 } },
  { range: "1,500 sqft - 2,500 sqft", prices: { "Interior & Exterior Photos": 183, "Cinematic Video": 143, "3D Matterport Virtual Tour with Floor Plan & Dimensions": 123, "Aerial Photography": 55, "Neighborhood Amenities": 45, "Twilight Exteriors": 115 } },
  { range: "2,500 sqft - 3,500 sqft", prices: { "Interior & Exterior Photos": 221, "Cinematic Video": 185, "3D Matterport Virtual Tour with Floor Plan & Dimensions": 145, "Aerial Photography": 59, "Neighborhood Amenities": 45, "Twilight Exteriors": 131 } },
  { range: "3,500 sqft - 4,500 sqft", prices: { "Interior & Exterior Photos": 260, "Cinematic Video": 228, "3D Matterport Virtual Tour with Floor Plan & Dimensions": 168, "Aerial Photography": 64, "Neighborhood Amenities": 45, "Twilight Exteriors": 146 } },
  { range: "4,500 sqft - 5,500 sqft", prices: { "Interior & Exterior Photos": 298, "Cinematic Video": 271, "3D Matterport Virtual Tour with Floor Plan & Dimensions": 191, "Aerial Photography": 68, "Neighborhood Amenities": 45, "Twilight Exteriors": 162 } },
  { range: "5,500 sqft+", prices: { "Interior & Exterior Photos": 355, "Cinematic Video": 335, "3D Matterport Virtual Tour with Floor Plan & Dimensions": 225, "Aerial Photography": 75, "Neighborhood Amenities": 45, "Twilight Exteriors": 185 } },
];

const serviceIds = {
  "Interior & Exterior Photos": "jsid7430207",
  "Cinematic Video": "jsid5704230",
  "3D Matterport Virtual Tour with Floor Plan & Dimensions": "jsid3724020",
  "Aerial Photography": "jsid6068937",
  "Neighborhood Amenities": "jsid3523690",
  "Aerial Photography Farms & Land": "jsid3039678",
  "Twilight Exteriors": "jsid1734010"
};

const OrlandoBooking = () => {
  const [selectedRange, setSelectedRange] = useState('');
  const [selectedServices, setSelectedServices] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);
  const [bookingUrl, setBookingUrl] = useState('');

  // Update total price and booking URL whenever services are selected or deselected
  useEffect(() => {
    const tier = pricingTiers.find(t => t.range === selectedRange);
    let newTotalPrice = 0;
    let serviceParams = '';

    if (tier) {
      newTotalPrice = Object.keys(selectedServices).reduce((acc, key) => {
        if (selectedServices[key]) {
          acc += tier.prices[key];
          serviceParams += `&service=${serviceIds[key]}`;
        }
        return acc;
      }, 0);
    }

    setTotalPrice(newTotalPrice);
    setBookingUrl(`https://xovate-orlando.ycb.me/?PRICEX=${newTotalPrice}${serviceParams}&TXTNOTIFY=yes`);
  }, [selectedServices, selectedRange]);
  const toggleService = (service) => {
    setSelectedServices((prevServices) => ({
      ...prevServices,
      [service]: !prevServices[service],
    }));
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>Orlando Booking XovateStudio 📸</h2>
      <div className={styles.form}>
        <div className={styles.selectContainer}>
          <label className={styles.label}>Square Footage Range:</label>
          <select
            value={selectedRange}
            onChange={(e) => setSelectedRange(e.target.value)}
            className={styles.input}
          >
            <option value="">Select Range</option>
            {pricingTiers.map((tier, index) => (
              <option key={index} value={tier.range}>{tier.range}</option>
            ))}
          </select>
        </div>
        Select Services
        {selectedRange && (
          <div className={styles.propertyInfo}>
            {Object.entries(pricingTiers.find(t => t.range === selectedRange).prices).map(([service]) => (
              <div
                key={service}
                className={`${styles.serviceOption} ${selectedServices[service] ? styles.serviceSelected : ''}`}
                onClick={() => toggleService(service)}
              >
                {service} - ${pricingTiers.find(t => t.range === selectedRange).prices[service]}
              </div>
            ))}
          </div>
        )}
        {totalPrice > 0 && (
          <div className={styles.statusBox}>
            <h3 className={styles.totalPrice}>Total Price: ${totalPrice}</h3>
            <button onClick={() => window.location.href = bookingUrl} className={styles.continueButton}>
              Continue Booking
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrlandoBooking;